/*
 * @Author: wangliang
 * @Date: 2023-08-16 14:28:05
 * @LastEditTime: 2024-09-26 09:33:22
 * @LastEditors: wangliang
 * @Description: 
 */
import {BrowserRouter} from 'react-router-dom';
import {useReducer, useEffect} from 'react';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import AppRouter from '@/router';
import {Context, reducer} from '@/redux';
import {systemInfo} from "@/config/config"
import './App.css';

dayjs.locale('zh-cn');

function App() {
  const [state, dispatch] = useReducer(reducer, {});
  const updateMetaTags = () => {
    document.title = '余小应'
    // 更新 keywords
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute('content', 'keywords');
    }

    // 更新 description
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', 'description');
    }

    // 更新 favicon
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.href = 'yu_favicon.ico' + '?v=' + Date.now(); // 替换为新图标的路径

    const oldFavicon = document.querySelector('link[rel~="icon"]');
    if (oldFavicon && oldFavicon.parentNode) {
      oldFavicon.parentNode.removeChild(oldFavicon);
    }
    document.head.appendChild(newFavicon);
  };
  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const token = urlParams.get('token');
    const hostname = window.location.hostname
    if(hostname === 'xiaoying.gingpt.cn') {
      updateMetaTags();
      dispatch({type: 'systemInfo', data: systemInfo[1]});
    }else {
      dispatch({type: 'systemInfo', data: systemInfo[0]});
    }

    return () => {
      // 清理操作（如果有）
    };
  }, []);
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
            <BrowserRouter>
              <Context.Provider value={{state, dispatch}}>
                <AppRouter/>
              </Context.Provider>
            </BrowserRouter>
        </ConfigProvider>
    </div>
  );
}

export default App;
